// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-marianne-js": () => import("./../../../src/pages/about-marianne.js" /* webpackChunkName: "component---src-pages-about-marianne-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-courses-retreats-and-special-events-js": () => import("./../../../src/pages/courses-retreats-and-special-events.js" /* webpackChunkName: "component---src-pages-courses-retreats-and-special-events-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-fees-forms-and-insurance-js": () => import("./../../../src/pages/fees-forms-and-insurance.js" /* webpackChunkName: "component---src-pages-fees-forms-and-insurance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-questions-for-reflection-js": () => import("./../../../src/pages/questions-for-reflection.js" /* webpackChunkName: "component---src-pages-questions-for-reflection-js" */),
  "component---src-pages-services-and-specialties-js": () => import("./../../../src/pages/services-and-specialties.js" /* webpackChunkName: "component---src-pages-services-and-specialties-js" */)
}

